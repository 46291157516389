<footer class="site-footer dark">
  <div class="container">
    <div class="copy">
      &copy; {{ copyrightYears }} Wilson Language Training Corp.
      All&nbsp;Rights&nbsp;Reserved.
    </div>
    <ul class="links spacers">
      <li>
        <a
          href="https://www.wilsonlanguage.com/digital-products-terms-of-service/"
          target="_blank"
          data-automationId="footer_terms"
          >Terms & Conditions</a
        >
      </li>
      <li>
        <a
          href="https://www.wilsonlanguage.com/digital-products-privacy-statement/"
          target="_blank"
          data-automationId="footer_privacy"
          >Privacy</a
        >
      </li>
      <li>
        <a
          href="https://www.wilsonlanguage.com/wp-content/uploads/2015/12/WLT-Trademark-Guidelines.pdf"
          target="_blank"
          data-automationId="footer_trademark"
          >Trademark Guidelines</a
        >
      </li>
      <li>
        <a
          [routerLink]="sitemapLink"
          data-automationId="footer_sitemap"
          target="_blank"
          >Site Map</a
        >
      </li>
    </ul>
  </div>
</footer>
