import { Injectable } from '@angular/core';
import { ApiService } from '@wilson/wilsonng';
import algoliasearch, { SearchIndex } from 'algoliasearch/lite';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialLoadService } from './initial-load.service';
import { UserService } from './user.service';
import { createNullCache } from '@algolia/cache-common';
import { Router } from '@angular/router';
import { ResourceNotificationService } from './resource-notification.service';
import { Wilson } from 'src/def/Wilson';
import Resource = Wilson.Resource;
import Search = Wilson.Search;
import Directory = Wilson.Directory;
import ResourceType = Wilson.ResourceType;

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  index: SearchIndex;
  suggestionsIndex: SearchIndex;
  admin: boolean;

  constructor(
    private initialLoadService: InitialLoadService,
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private resourceNotificationService: ResourceNotificationService
  ) {
    this.initialize();
  }

  initialize(): void {
    // app name, search-only api key (public)
    const client = algoliasearch(
      'FIQ2XBD6QA',
      '0c187e0f97d31805ebb33b1c7f188d57',
      {
        responsesCache: createNullCache(),
        headers: { 'X-Algolia-UserToken': this.userService.user.id },
      }
    );

    this.index = client.initIndex(
      this.initialLoadService.initialLoad.tokens['ALGOLIA_INDEX_RESOURCE']
    );
    this.suggestionsIndex = client.initIndex(
      this.initialLoadService.initialLoad.tokens[
        'ALGOLIA_SUGGESTIONS_INDEX_RESOURCE'
      ]
    );

    this.admin = this.router.url.indexOf('admin') > -1;
  }

  suggestion(search: string, length = 5): Promise<string[]> {
    return this.suggestionsIndex
      .search(search, {
        hitsPerPage: length,
      })
      .then((payload) => {
        return payload.hits.map(
          (suggestion) => suggestion._highlightResult['query']['value']
        );
      });
  }

  search(
    search: string,
    types: ResourceType[] = [],
    tags: string[] = [],
    parentProgramIds = null,
    page = 0,
    pageLength = 6,
    childResourceType: 'Collection' | 'Primitive' = null
  ): Observable<SearchResults> {
    if (!parentProgramIds) {
      parentProgramIds = [this.userService.user.lastViewedProgramId];
    }
    if (!tags) {
      tags = [];
    }

    if (!types) {
      types = [];
    }

    return this.primarySearch(
      search,
      types,
      tags,
      parentProgramIds,
      page,
      pageLength,
      childResourceType
    ).pipe(
      catchError((err) => {
        console.error(err);
        // if any error, call backup search
        return this.backupSearch(
          search,
          types,
          tags,
          parentProgramIds,
          page,
          pageLength
        );
      })
    );
  }

  private makeFacetArray(
    keyword: string,
    array: string[],
    joiner: 'AND' | 'OR',
    firstQueryGroup = true,
    endParen = true
  ) {
    let ret = '';
    array = array.filter((x) => x && x.length);
    if (array && array.length) {
      if (firstQueryGroup) {
        ret += `(${keyword}:`;
      } else {
        ret += ` AND (${keyword}:`;
      }
      ret += array.join(` ${joiner} ${keyword}:`);
      if (endParen) {
        ret += ')';
      }
    }
    return ret;
  }

  private determineFacetFilters(parentProgramIds: string[]): string {
    let facetFilters = '';
    if (!this.admin) {
      facetFilters = 'isPublished:true AND isSearchable:true AND ';
    }
    if (parentProgramIds.length == 1) {
      facetFilters += `(parentProgramIds:${parentProgramIds[0]} OR parentProgramId:${parentProgramIds[0]}) `;
    } else if (parentProgramIds.length > 1) {
      facetFilters += this.makeFacetArray(
        'parentProgramIds',
        parentProgramIds,
        'OR'
      );
    }

    return facetFilters;
  }

  private buildFacetFilters(
    types: ResourceType[],
    tags: string[],
    parentProgramIds: string[],
    childResourceType: 'Collection' | 'Primitive'
  ): string {
    if (childResourceType) {
      // remove collection for the types if we're looking for a child resource type
      // "collection" as a type is implied if we're looking for this
      types = types.filter((t) => t !== ResourceType.collection);
    }

    let facetFilters = this.determineFacetFilters(parentProgramIds);
    facetFilters += this.makeFacetArray(
      'tagIds',
      tags,
      'AND',
      parentProgramIds.length < 1
    );

    facetFilters += this.makeFacetArray(
      'type',
      types,
      'OR',
      parentProgramIds.length < 1 && tags.length < 1,
      false
    );

    if (childResourceType) {
      // if there are no types, add collections type
      // otherwise, just add an or
      facetFilters += ` ${types.length === 0 ? '(type:collection AND' : 'OR'} ${
        childResourceType === 'Collection' ? '' : 'NOT'
      } childResourceType:collection`;
    }

    // close "types" paren we left open for child resource type checking
    facetFilters += ')';

    return facetFilters;
  }

  private primarySearch(
    search: string,
    types: ResourceType[],
    tags: string[],
    parentProgramIds: string[],
    page: number,
    pageLength: number,
    childResourceType: 'Collection' | 'Primitive'
  ): Observable<SearchResults> {
    const facetFilters = this.buildFacetFilters(
      types,
      tags,
      parentProgramIds,
      childResourceType
    );

    return from(
      this.index.search(search, {
        filters: facetFilters,
        attributesToRetrieve: [
          'name',
          'description',
          'type',
          'imageUrl',
          'url',
          'openInNewWindow',
          'parentProgramId',
          'createdDate',
          'lastModifiedDate',
          'notificationOverride',
          'showNotifications',
          'notification',
        ],
        attributesToHighlight: ['name', 'description'],
        facets: ['tagIds', 'type', 'parentProgramIds'],
        page: page,
        hitsPerPage: pageLength,
        analyticsTags: [this.userService.user.lastViewedProgramId],
      })
    ).pipe(
      map((payload) => {
        return <SearchResults>{
          totalItems: payload.nbHits,
          itemsPerPage: payload.hitsPerPage,
          pageCount: payload.nbPages,
          currentPage: payload.page,
          availableFacets: payload.facets
            ? { ...payload.facets['tagIds'], ...payload.facets['type'] }
            : null,
          availablePrograms: payload.facets['parentProgramIds'],
          items: payload.hits.map((res) => {
            const resource = res as unknown as Resource;
            resource.id = res['objectID'];
            resource.isPinned =
              this.userService.user.resourcesPinned?.includes(
                res['objectID']
              ) || false;

            Object.keys(res._highlightResult).forEach((key) => {
              resource[key] = res._highlightResult[key].value;
            });

            this.resourceNotificationService.determineNotifications(resource);
            return resource;
          }),
        };
      })
    );
  }

  private backupSearch(
    filter: string,
    types: ResourceType[],
    tags: string[],
    parentProgramIds,
    page: number,
    pageLength: number
  ): Observable<SearchResults> {
    const searchModel: Search = {
      filter,
      types,
      tags,
      parentProgramIds,
      sortProperty: 'Name',
      sortDirection: 1,
      skip: page * pageLength,
      take: pageLength,
    };

    return this.apiService
      .post<Directory<Resource>>('resource/SearchResources', searchModel)
      .pipe(
        map((directory) => {
          return <SearchResults>{
            totalItems: directory.count,
            itemsPerPage: pageLength,
            pageCount: Math.floor(directory.count / pageLength) + 1,
            currentPage: searchModel.skip / pageLength,
            items: directory.items.map((item) => {
              item.isPinned =
                this.userService.user.resourcesPinned?.includes(item.id) ||
                false;
              this.resourceNotificationService.determineNotifications(item);
              return item;
            }),
          };
        })
      );
  }
}

export interface SearchResults {
  items: Resource[];
  totalItems: number;
  itemsPerPage: number;
  pageCount: number;
  currentPage: number;
  availableFacets: Record<string, number>;
  availablePrograms: Record<string, number>;
}
