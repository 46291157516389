{
  "name": "communities",
  "version": "1.36.0",
  "scripts": {
    "ng": "ng",
    "start": "rundev",
    "build": "ng build",
    "build:ssr": "ng run communities:server:dev",
    "test": "ng test",
    "lint": "npx eslint",
    "e2e": "ng e2e",
    "pretty-quick": "pretty-quick"
  },
  "private": true,
  "dependencies": {
    "@angular-eslint/schematics": "^16.2.0",
    "@angular/animations": "^16.2.0",
    "@angular/cdk": "^16.2.0",
    "@angular/common": "^16.2.0",
    "@angular/compiler": "^16.2.0",
    "@angular/core": "^16.2.0",
    "@angular/forms": "^16.2.0",
    "@angular/platform-browser": "^16.2.0",
    "@angular/platform-browser-dynamic": "^16.2.0",
    "@angular/platform-server": "^16.2.0",
    "@angular/router": "^16.2.0",
    "@datadog/browser-rum": "^2.17.0",
    "@popperjs/core": "^2.9.2",
    "@types/mixpanel-browser": "^2.38.0",
    "@types/offscreencanvas": "^2019.6.4",
    "@wilson/styles": "^2.0.1",
    "@wilson/wilsonng": "^16.2.0",
    "algoliasearch": "^4.10.3",
    "bootstrap": "^5.0.2",
    "chart.js": "^3.7.1",
    "core-js": "^3.15.2",
    "cropperjs": "^1.5.12",
    "devextreme": "^23.1.3",
    "devextreme-angular": "^23.1.3",
    "devextreme-aspnet-data-nojquery": "^2.9.0",
    "devextreme-schematics": "^1.5.0",
    "jquery": "^3.5.1",
    "lodash": "^4.17.20",
    "mixpanel-browser": "^2.45.0",
    "ngx-extended-pdf-viewer": "^18.1.9",
    "primeicons": "^6.0.1",
    "primeng": "^16.0.2",
    "quill": "^1.3.7",
    "rxjs": "^6.6.3",
    "tslib": "^2.3.0",
    "zone.js": "^0.13.1"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^16.2.0",
    "@angular-eslint/builder": "1.0.0",
    "@angular-eslint/eslint-plugin": "1.0.0",
    "@angular-eslint/eslint-plugin-template": "1.0.0",
    "@angular-eslint/template-parser": "1.0.0",
    "@angular/cli": "^16.2.0",
    "@angular/compiler-cli": "^16.2.0",
    "@angular/language-service": "15.1.0",
    "@types/algoliasearch": "^4.0.0",
    "@types/jasmine": "~3.8.1",
    "@types/jasminewd2": "^2.0.10",
    "@types/lodash": "^4.14.195",
    "@types/node": "^20.4.4",
    "@typescript-eslint/eslint-plugin": "^4.28.3",
    "@typescript-eslint/parser": "4.28.3",
    "devextreme-cli": "latest",
    "devextreme-themebuilder": "^22.1.3",
    "eslint": "^7.30.0",
    "eslint-config-prettier": "^8.3.0",
    "eslint-plugin-html": "^6.1.1",
    "eslint-plugin-import": "^2.23.4",
    "eslint-plugin-jsdoc": "35.4.3",
    "eslint-plugin-prefer-arrow": "1.2.3",
    "eslint-plugin-prettier": "^3.3.1",
    "jasmine-core": "~4.5.0",
    "jasmine-spec-reporter": "~5.0.0",
    "karma": "^6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "^2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.0.0",
    "prettier": "^2.8.0",
    "prettier-eslint": "^8.2.2",
    "pretty-quick": "^3.1.3",
    "sass": "^1.35.2",
    "tslint-config-prettier": "^1.18.0",
    "typescript": "^5.1.6"
  },
  "overrides": {
    "devextreme": {
      "rrule": {
        "luxon": "1.28.1"
      }
    }
  },
  "optionalDependencies": {
    "ts-node": "~8.4.1"
  }
}
